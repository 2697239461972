var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "16",
        viewBox: "0 0 22 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.6865 3.40457C10.8977 3.58696 11.0314 3.8567 11.0314 4.15765C11.0314 4.4586 10.8977 4.72831 10.6865 4.9107L7.98635 7.61087C7.59793 7.99929 6.96818 7.99929 6.57976 7.61087C6.19134 7.22245 6.19134 6.5927 6.57976 6.20428L7.63177 5.15225H1.94074C1.39143 5.15225 0.946121 4.70696 0.946121 4.15765C0.946121 3.60834 1.39143 3.16302 1.94074 3.16302H7.63177L6.57976 2.11102C6.19134 1.7226 6.19134 1.09282 6.57976 0.704402C6.96818 0.31598 7.59793 0.31598 7.98635 0.704402L10.6865 3.40457ZM8.77093 14C8.35975 15.1652 7.25027 16 5.94612 16C4.64197 16 3.5325 15.1652 3.12131 14H1.95404C1.40176 14 0.954044 13.5523 0.954044 13C0.954044 12.4477 1.40176 12 1.95404 12H3.12131C3.5325 10.8348 4.64197 10 5.94612 10C7.25027 10 8.35975 10.8348 8.77093 12H12.935V5C12.935 3.89543 13.829 3 14.9319 3H16.9287C20.2371 3 21.9208 4.68629 21.9208 8V12C21.9208 13.1046 21.0268 14 19.9239 14H18.7551C18.3439 15.1652 17.2344 16 15.9303 16C14.6261 16 13.5166 15.1652 13.1055 14H8.77093ZM14.9319 10.1707C15.2441 10.0602 15.5802 10 15.9303 10C17.2344 10 18.3439 10.8348 18.7551 12H19.9239V8C19.9239 5.79086 19.1343 5 16.9287 5H14.9319V10.1707ZM15.9303 14C16.4817 14 16.9287 13.5523 16.9287 13C16.9287 12.4477 16.4817 12 15.9303 12C15.3789 12 14.9319 12.4477 14.9319 13C14.9319 13.5523 15.3789 14 15.9303 14ZM5.94612 14C6.49753 14 6.94454 13.5523 6.94454 13C6.94454 12.4477 6.49753 12 5.94612 12C5.39471 12 4.9477 12.4477 4.9477 13C4.9477 13.5523 5.39471 14 5.94612 14Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }